import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import history from '../../history';


function Footer() {
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails,setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const hotelData = await fetch(
                        config.apiEndpoint + '/userwb/get-footer-data/' + company_id + '/' + template_id

                    );
                    const json = await hotelData.json();
                    const result = json.data;
                    
                    var all_menu_details = {
                        "footer": {
                            "footer_menus": result.footer_menu_details,
                        }
                    }

                    let numbers = [];
                    let emails=[];
                    if (result && result.hotel_details) {
                        numbers = result.hotel_details.original[0].mobile.split(',');
                        emails=result.hotel_details.original[0].email_id.split(',');
                    }
                    setwebsiteDetails({ "menu_details": all_menu_details, "template_identity": result && result.template_identity,"hotel_details":result && result.hotel_details,"hotel_menus":result && result.hotel_menu_details,"phone_numbers": numbers,"emails":emails });
                    
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])
    const websitefont=websiteDetails && websiteDetails.template_identity[0].theme_font
    var hotelDetails = websiteDetails && websiteDetails.hotel_details.original[0]

    const redirectTo = (page) => {
        if (page.page_url === '' || page.page_url === ' ') {
            history.push(config.PATH + page.page);
        } else {
            var page_url = page.page_url
            if (page_url.includes("http://") || page_url.includes("https://")) {
                window.open(page_url)
            }
            else {
                if (page_url.includes("www")) {
                    page_url = page_url.trimStart()
                    window.open("http://" + page_url)
                }

                else {
                    history.push(config.PATH + page_url);
                }
            }
        }
    }
    return (
        <div>
            <footer className="footer_area">
                <div className="footer_widget_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-xs-6">
                                <aside className="f_widget about_widget">
                                {websiteDetails &&
                                    <img src={config.wbImagesEndpoint + '/' + websiteDetails.template_identity[0].logo} alt={websiteDetails.template_identity[0].logo_alt}/>}
                                    <div className="ab_wd_list" style={{color:"#757575"}}>
                                        <div className="media">
                                            <div className="media-left">
                                                <i className="fa fa-map-marker"></i>
                                            </div>
                                            <div className="media-body">
                                                {websiteDetails &&
                                                    <h4 style={{fontFamily:websitefont}}><b>{websiteDetails.hotel_details.original[0].hotel_address}</b></h4>
                                                }
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="media-left">
                                                <i className="fa fa-phone"></i>
                                            </div>
                                            <div className="media-body">
                                                <h4 style={{ fontFamily: websitefont}}>
                                                    {websiteDetails && websiteDetails.phone_numbers.length > 0 && websiteDetails.phone_numbers.map(function (items, i) {
                                                        return (
                                                            <span key={i}><a href={"tel:" + items} style={{ color: "inherit"}} ><b>{items}</b></a>{i < websiteDetails.phone_numbers.length - 1 && <span>,</span>}</span>
                                                        )
                                                    })}
                                                </h4>

                                            </div>
                                        </div>

                                        <div className="media">
                                            <div className="media-left">
                                                <i className="fa fa-envelope-o"></i>
                                            </div>
                                            <div className="media-body">
                                                

                                                <h4 style={{ fontFamily: websitefont, marginTop: "0px" }}>
                                                    {websiteDetails && websiteDetails.emails.length > 0 && websiteDetails.emails.map(function (items, i) {

                                                        return (
                                                            <span key={i}><a href={"mailto:" + items} style={{ color: "inherit" }} ><b>{items}</b></a>{ i< websiteDetails.emails.length-1 &&<br />}</span>
                                                        )
                                                    })}                                                    

                                                </h4>
                                            </div>
                                        </div>

                                    </div>
                                </aside>
                            </div>
                            <div className="col-md-4 col-xs-6">
                                <aside className="f_widget link_widget">
                                    <div className="f_title menu_title">
                                        {websiteDetails && websiteDetails.menu_details && <h3 style={{ fontFamily: websitefont }} className={websiteDetails.menu_details.footer.footer_menus.length>3 ? 'text-center':''}>Menu</h3>}
                                    </div>

                                    <ul>
                                    </ul>
                                    <div className="row">
                                        <div className="col-md-12 col-xs-12">
                                            {websiteDetails && websiteDetails.menu_details.footer.footer_menus.map(function (item, i) {
                                                if (item.page_id === 1) {
                                                    item.page = item.page_url;
                                                }
                                                if (i < 3) {
                                                    return (
                                                        <ul key={i}>
                                                            <li><a style={{ fontFamily: websitefont, cursor: "pointer" }}
                                                                onClick={() => redirectTo(item)} className="footer_menu">{item.menu_name}
                                                            </a></li>
                                                        </ul>)
                                                }

                                            })}
                                        </div>

                                        <div className="col-md-6 col-xs-12">
                                            {websiteDetails && websiteDetails.menu_details.footer.footer_menus.map(function (item, i) {
                                                if (item.page_id === 1) {
                                                    item.page = item.page_url;
                                                }
                                                if (i >= 3) {
                                                    return (
                                                        <ul key={i}>
                                                            <li><a style={{ fontFamily: websitefont, cursor: "pointer" }}
                                                                onClick={() => redirectTo(item)} className="footer_menu">{item.menu_name}
                                                            </a></li>
                                                        </ul>)
                                                }

                                            })}
                                        </div>
                                    </div>
                                </aside>
                            </div>

                            <div className="col-md-4 col-xs-6">
                                <aside className="f_widget link_widget">
                                    <div className="f_title">
                                        <h3 style={{ fontFamily: websitefont }}>Social Links</h3>
                                    </div>
                                    <ul className="footer-links-social">
                                        <li><a href={(websiteDetails && hotelDetails && hotelDetails.facebook_link) ? hotelDetails.facebook_link : "#"} ><i className="fa fa-facebook"></i></a></li>
                                        <li><a href={(websiteDetails && hotelDetails && hotelDetails.instagram_link) ? hotelDetails.instagram_link : "#"} ><i className="fa fa-instagram"></i></a></li>
                                        <li><a href={(websiteDetails && hotelDetails && hotelDetails.tripadvisor_link) ? hotelDetails.tripadvisor_link : "#"} ><i className="fa fa-tripadvisor"></i></a></li>
                                        <li><a href={(websiteDetails && hotelDetails && hotelDetails.twitter_link) ? hotelDetails.twitter_link : "#"}><i className="fa fa-twitter"></i></a></li>
                                    </ul>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_copyright_area">
                    <div className="container">
                        <div className="pull-left">
                        {websiteDetails && hotelDetails &&
                            <h4>Copyright © {hotelDetails.hotel_name} . All rights reserved. </h4>
                        }
                        </div>
                        <div className="pull-right">
                            <h4>Created by: <a href="https://bookingjini.com/"target="_blank"><img src={config.apiEndpoint + '/public/templateimage/jini_logo.png'} style={{marginTop:"-10px",width:"120px"}}/></a></h4>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default Footer